
<template>
	<div v-if="languages">

		<div class="p-4 bg-primary text-white border-primary border text-center" v-if="limitations.length > 0">
			<div class="my-2">You can make changes only with the following languages:</div>
			<div class="text-uppercase my-2 font-weight-bold">{{ limitations.join(", ") }}</div>
			<div>-</div>
			<div class="font-weight-bold my-2">Any changes to non-enabled languages will be discarded</div>
		</div>

		<div class="bg-light p-3 d-flex justify-content-between">
			<div class="flex-grow-1 text-left">
				<h4>Edit translation</h4>

				<select class="form-control" v-model="currentLanguage">
					<option :value="language.Code" v-for="language in allowedLanguages"
									:disabled="language.Code === currentLanguage">{{ language.Name }}
					</option>
				</select>

			</div>

			<div class="flex-grow-1 text-left">
				<h4>Original content language</h4>

				<select class="form-control" v-model="mainLanguage">
					<option :value="language.Code" v-for="language in languages"
									:disabled="language.Code === currentLanguage">{{ language.Name }}
					</option>
				</select>

			</div>


			<div class="mr-5">
				<h4>Import JSON</h4>
				<div class="d-flex mt-2">
					<router-link :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-upload',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: $route.params.project_id
											}
				}" class="btn btn-outline-primary">Import
					</router-link>
				</div>
			</div>
			<div class="mr-4">
				<h4>Translations</h4>
				<div v-if="loading" class="spinner-border" role="status">
					<span class="sr-only">Loading...</span>
				</div>
				<button @click="generateTranslations" :disabled="this.currentLanguage == this.defaultLanguage" class="btn btn-info" v-if="!loading">Generate</button>
			</div>
			<div>
				<h4>Download JSON</h4>
				<a :href="downloadableJSON" download="translations.json" class="btn btn-success" v-if="updated">Export</a>
				<div class="text-small py-2 text-muted" v-else>Please save before download</div>
			</div>
		</div>

		<table class="table table-borderless table-shrikned" v-if="currentLanguage">
			<thead>
			<tr>
				<th>ID</th>
				<th class="w-100">
					<h1 class="text-uppercase">{{ currentLanguage.Name }}</h1>
				</th>
			</tr>
			</thead>
			<tr v-for="(token, token_id) in model" :key="token.ID" class="py-2">
				<td class="text-left font-weight-bold">
					<div>{{ token.ID }}</div>

				</td>
				<td class="pb-4 bg-light my-2">
					<localization-editor-token :disabled="disabled" @change="setChanged(token.ID)"
																		 v-model="model[token_id][currentLanguage]"
																		 :original-message="model[token_id][mainLanguage]">

						<div class="d-flex" v-if="mainLanguage !== currentLanguage">
							<a class="btn btn-outline-primary text-small mr-1" target="translateWindow"
								 :href="`https://translate.google.com/#view=home&op=translate&sl=${mainLanguage.substr(0, 2)}&tl=${currentLanguage.substr(0, 2)}&text=${encodeURIComponent(model[token_id][mainLanguage])}`">Google
								translate</a>

							<a class="btn btn-outline-primary text-small mr-1" target="translateWindow"
								 :href="`https://www.deepl.com/${currentLanguage}/translator#${mainLanguage.substr(0, 2)}/${currentLanguage.substr(0, 2)}/${encodeURIComponent(model[token_id][mainLanguage])}`">DeepL</a>

							<a class="btn btn-outline-primary text-small mr-1" target="translateWindow"
								 :href="`https://translate.yandex.com/?lang=${mainLanguage.substr(0, 2)}-${currentLanguage.substr(0, 2)}&text=${encodeURIComponent(model[token_id][mainLanguage])}`">Yandex</a>

							<a class="btn btn-outline-primary text-small mr-1" target="translateWindow"
								 :href="`https://translate.systran.net/translationTools/text?lang=${mainLanguage.substr(0, 2)}&source=${mainLanguage.substr(0, 2)}&target=${currentLanguage.substr(0, 2)}&input=${encodeURIComponent(model[token_id][mainLanguage])}`">Systran</a>

						</div>
						<div class="mt-2">Editing {{ currentLanguage.Name }} version</div>
					</localization-editor-token>
				</td>
			</tr>
		</table>
	</div>
</template>
<script>
import api from '@/api'
import LocalizationEditorToken from "./LocalizationEditorToken";

export default {
	name: 'localization-editor',
	components: {LocalizationEditorToken},
	props: {
		value: {},
		updated: {
			default: true,
			type: Boolean
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	data: () => ({
		languages: null,
		currentLanguage: null,
		defaultLanguage: null,
		mainLanguage: 'en',
		loading: false,
	}),
	methods: {
		setChanged(token) {
			this.$emit("changed", token)
		},
		async generateTranslations() {
			this.loading = true
			var response = await api.Localizations.LocalizationProjects.generateContentTranslations({
				LocalizationProjectfk: this.$route.params.project_id,
				language: this.currentLanguage
			}).finally(() => this.loading = false);
			alert(response);
			this.$router.go(0);
		},
	},
	async mounted() {
		this.languages = await api.Localizations.Languages.getList()
		this.currentLanguage = (this.allowedLanguages.filter(l => l.Code !== this.mainLanguage)[0]).Code
		this.defaultLanguage = (this.languages.filter(l => l.DefaultForTranslation == 1)[0]).Code
	},
	computed: {
		allowedLanguages() {
			let allowed = this.languages
			if (this.limitations.length > 0) {
				allowed = this.languages.slice().filter(l => this.limitations.includes(l.Code))
			}
			return allowed
		},
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		},
		missingTranslations() {
			let languages = {}

			this.languages.forEach(l => languages[l.Code] = 0)

			return this.model.reduce((c, m) => {
				this.languages.forEach(l => {
					if (m[l.Code] === undefined || m[l.Code] === "") {
						c[l.Code]++
					}
				})
				return c
			}, languages)
		},
		downloadableJSON() {
			return `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.model))}`
		},
		limitations() {
			let limitations = (this.$store.getters['User/getUser'].Limitations)
			let languages = []

			if (limitations !== null) {
				languages = limitations.languages
			}

			return languages;
		}
	}
}
</script>
