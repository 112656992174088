<template>
	<div>
		<textarea :disabled="disabled" tabindex="-1" :placeholder="originalMessage" class="form-control animated" rows="3"
							v-model="model"
							:class="{'border border-danger': model === '', 'bg-warning text-danger': missingVariables.length > 0}"></textarea>
		<div class="border bg-light p-2 my-2">
			<div class="p-2">
				<div class="text-small">Original content:</div>
				<div class="p-2 border bg-white my-2">
					<div v-html="originalMessageHTML"></div>
				</div>
			</div>

			<div v-if="missingVariables.length > 0" class="p-2">
				There are {{ missingVariables.length }} missing variables:
				<div class="text-info">
					<div v-for="_var in missingVariables" class="my-1 ml-0">
						<div v-text="_var" class="bg-info text-white p-1 rounded d-inline-block"></div>
					</div>
				</div>
				<div class="mt-2 text-dark">
					Check the syntax please, no spaces allowed between $ and {{ missingVariables[0].substr(1) }}.
				</div>
			</div>

			<slot></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: 'localization-editor-token',
	props: {
		value: {},
		originalMessage: {},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	methods: {
		getVariables(text) {
			const regexp = /(\$[a-zA-Z]+[a-zA-Z0-9]*)?/g;

			if (text === undefined) {
				text = ""
			}

			return [...text.matchAll(regexp)].map(m => m[1]).filter(f => f !== undefined).map(m => m.trim()).sort().filter((item, index, array) => array.indexOf(item) === index)
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
				this.$emit("change", val)
			}
		},
		variablesOriginalMessage() {
			return this.getVariables(this.originalMessage)
		},
		variablesInModel() {
			return this.getVariables(this.model)
		},
		missingVariables() {
			return (this.variablesOriginalMessage).filter(v => !this.variablesInModel.includes(v))
		},
		originalMessageHTML() {

			let ret_str = this.originalMessage.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')

			let variables = this.variablesOriginalMessage.slice()

			for (let i = 0; i < variables.length; i++) {
				const mv = variables[i]
				let pattern = `\\${mv} ?`
				const regexp = new RegExp(pattern, "g")
				ret_str = ret_str.replace(regexp, `<span class="bg-info text-white px-1 mr-1 label py-1 rounded font-weight-bold">${mv}</span>`)
			}

			return ret_str
		}
	}
}
</script>
